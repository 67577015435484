export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
      children: [
        {
          name: 'new-policy',
          displayName: 'New Policy',
          meta: {
            icon: 'list_alt_add',
          },
        },
        {
          name: 'pending-policies',
          displayName: 'Pending Policies',
          roles: 'SUPER_ADMIN,ADMIN,FINANCE,AGENT',
          meta: {
            icon: 'list_alt',
          },
        },
        {
          name: 'accepted-policies',
          displayName: 'Accepted Policies',
          roles: 'SUPER_ADMIN,ADMIN,FINANCE,AGENT',
          meta: {
            icon: 'checklist_rtl',
          },
        },
        {
          name: 'active-policies',
          displayName: 'Active Policies',
          roles: 'SUPER_ADMIN,ADMIN,FINANCE,AGENT',
          meta: {
            icon: 'checklist_rtl',
          },
        },
      ],
    },
    {
      name: 'claims',
      displayName: 'Claims',
      meta: {
        icon: 'assessment',
      },
      children: [
        {
          name: 'new-claim',
          displayName: 'New claim',
          meta: {
            icon: 'list_alt_add',
          },
        },
      ],
    },
    /*
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'projects',
      displayName: 'menu.projects',
      meta: {
        icon: 'folder_shared',
      },
    },
    {
      name: 'payments',
      displayName: 'menu.payments',
      meta: {
        icon: 'credit_card',
      },
      children: [
        {
          name: 'payment-methods',
          displayName: 'menu.payment-methods',
        },
        {
          name: 'pricing-plans',
          displayName: 'menu.pricing-plans',
        },
        {
          name: 'billing',
          displayName: 'menu.billing',
        },
      ],
    },
    {
      name: 'auth',
      displayName: 'menu.auth',
      meta: {
        icon: 'login',
      },
      children: [
        {
          name: 'login',
          displayName: 'menu.login',
        },
        {
          name: 'signup',
          displayName: 'menu.signup',
        },
        {
          name: 'recover-password',
          displayName: 'menu.recover-password',
        },
      ],
    },

    {
      name: 'faq',
      displayName: 'menu.faq',
      meta: {
        icon: 'quiz',
      },
    },
    {
      name: '404',
      displayName: 'menu.404',
      meta: {
        icon: 'vuestic-iconset-files',
      },
    },
    {
      name: 'preferences',
      displayName: 'menu.preferences',
      meta: {
        icon: 'manage_accounts',
      },
    },
    {
      name: 'settings',
      displayName: 'menu.settings',
      meta: {
        icon: 'settings',
      },
    },

 */
  ] as INavigationRoute[],
}
