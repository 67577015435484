import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import { createGtm } from '@gtm-support/vue-gtm'

import stores from './stores'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import axios from 'axios'
import App from './App.vue'

const app = createApp(App)

function BEUrl() {
  // This function will be called once when the app is created and be loaded
  // from the environment variables
  //
  // Possible values include:
  // - import.meta.env.VITE_APP_BASE_BE_URL
  // - 'https://uat.capinsurance.ca/api'
  // - 'https://prod.capinsurance.ca/api'

  return { be_url: 'https://uat.capinsurance.ca/api' }
}

stores.use(BEUrl)

app.use(stores)
app.use(router)
app.use(i18n)
app.use(createVuestic({ config: vuesticGlobalConfig }))
app.use(axios)

if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  )
}

app.mount('#app')
