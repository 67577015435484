import { defineStore } from 'pinia'
import axios from 'axios'
//import { waitFor } from '@storybook/testing-library'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    role: null,
    token: null,
    error: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
  },
  actions: {
    setUser(thisUser) {
      this.user = thisUser
      localStorage.setItem('user', thisUser)
    },
    reset() {
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem('token')
      this.token = null
    },
    async getMe() {
      try {
        if (this.user != null && this.role != null && this.user != undefined && this.role != undefined) {
          return
        }
        //if(this.token==null) {
        //  this.token = localStorage.getItem("token")
        //}
        //axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
        this.getLocal()
        const response = await axios.get(this.be_url + '/auth/getme') // Replace with your API endpoint
        this.user = response.data.user
        this.role = this.user.roles[0].name
        this.setLocal()
      } catch (err) {
        this.error = err.response?.data?.message || err.message
      }
    },
    async login(credentials) {
      try {
        this.reset()
        const response = await axios.post(this.be_url + '/auth/login', credentials) // Replace with your API endpoint
        this.user = response.data.user
        this.role = this.user.roles[0].name
        this.token = response.data.access_token
        this.setLocal()
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` // Set default Authorization header
      } catch (err) {
        this.error = err.response?.data?.message || err.message
      }
    },
    async quickauth(credentials) {
      try {
        this.reset()
        const response = await axios.post(this.be_url + '/auth/quickauth', credentials) // Replace with your API endpoint

        this.user = response.data.user
        this.role = response.data.user.roles[0].name
        this.token = response.data.access_token
        this.setLocal()
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` // Set default Authorization header
      } catch (err) {
        this.error = err.response?.data?.message || err.message
      }
    },
    async logout() {
      try {
        this.user = null
        this.token = null
        this.voidLocal()
        await axios.post(import.meta.env.BASE_URL + '/api/auth/logout') // Replace with your API endpoint
      } catch (err) {
        this.error = err.response?.data?.message || err.message
      }
    },
    async fetchUser() {
      try {
        const response = await axios.get(this.be_url + '/auth/user') // Replace with your API endpoint
        this.user = response.data
      } catch (err) {
        this.error = err.response?.data?.message || err.message
      }
    },
    voidLocal() {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('role')
      //debugger
      delete axios.defaults.headers.common['Authorization']
    },
    getLocal() {
      const token = localStorage.getItem('token')
      if (token) {
        this.token = token
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      }
      const user = localStorage.getItem('user')
      if (user) {
        this.user = JSON.parse(user)
      }
      const role = localStorage.getItem('role')
      if (role) {
        this.role = role
      }
    },
    setLocal() {
      localStorage.setItem('token', this.token)
      localStorage.setItem('user', JSON.stringify(this.user))
      localStorage.setItem('role', this.role)
    },
    initialize() {
      this.getLocal()
    },
  },
})
